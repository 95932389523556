
import { defineComponent, onMounted, ref, watch } from "vue"
import FilterBaseV2, { IApiDataEmit, IClientDataEmit } from "@/layout/header/components/FilterBaseV2.vue"
import useAlert from "@/composables/Alert"
import { useHomeStore } from '@/store/HomeStore'
import { useLoaderStore } from '@/store/LoaderStore'
import { ElConfigProvider } from "element-plus"
import ptBr from "element-plus/lib/locale/lang/pt-br"
import { CURRENT_MONTH, getAllData, START_OF_YEAR } from "@/views/Home/HomeView.vue"
import moment from 'moment'
import { useFilterBaseV2Store } from "@/store/FilterBaseV2Store"

export default defineComponent({
  name: "FilterHomeView",
  components: {
    FilterBaseV2,
    ElConfigProvider,
  },
  setup(props, { emit }) {
    /** Variables */
    const { showTimeAlert } = useAlert()
    const isLoading = ref(true)
    const isActiveModal = ref(false)
    const isCloseModal = ref(false)
    const loaderStore = useLoaderStore()
    const homeStore = useHomeStore()
    const filterBaseV2Store = useFilterBaseV2Store()
    const startDate = ref(START_OF_YEAR)
    const endDate = ref(CURRENT_MONTH)
    const wrongField = ref(false)
    const dateWarning = ref({
      isError: false,
      message: ""
    })

    /** Functions */
    function resetFields() {
      startDate.value = START_OF_YEAR
      endDate.value = CURRENT_MONTH
    }

    function handleInitFields() {
      const filterFields = homeStore.getFilterFields
      
      if(!filterFields)
        return resetFields()

      if(filterFields.start_date && filterFields.end_date) {
        startDate.value = filterFields.start_date
        endDate.value = filterFields.end_date
        return
      }

      return resetFields()
    }

    async function handleResetFields(emit: IClientDataEmit) {
      const company = await filterBaseV2Store.getCompanyInSession()
      if(emit.companyId !== company?.id)
        resetFields()
    }

    function handleDateWarning() {
      const start = moment(startDate.value).valueOf()
      const end = moment(endDate.value).valueOf()
      wrongField.value = false
      dateWarning.value.message = ""

      if(start > end) {
        wrongField.value = true
        dateWarning.value.message = `${moment(start).format('MM/YYYY')} não pose ser maior que ${moment(end).format('MM/YYYY')}!`
      }

      if(startDate.value && !endDate.value) {
        wrongField.value = true
        dateWarning.value.message = "Data final é obrigatório!"
      }

      if(endDate.value && !startDate.value) {
        wrongField.value = true
        dateWarning.value.message = "Data inicial é obrigatório!"
      }

      dateWarning.value.isError = wrongField.value
    }

    async function handleExecuteSearch(emit: IApiDataEmit) {
      if(dateWarning.value.isError)
        return

      /** Init proccess search */
      isCloseModal.value = false
      loaderStore.open()
      if(!emit.loading) {
        /** Field Date */
        const start_date = !!startDate.value
          ? moment(startDate.value).format('YYYY-MM')
          : START_OF_YEAR
        startDate.value = start_date

        const end_date = !!endDate.value
          ? moment(endDate.value).format('YYYY-MM')
          : CURRENT_MONTH
        endDate.value = end_date

        const month = endDate.value

        /** Submit */
        await getAllData(homeStore, showTimeAlert, emit.companyId, start_date, end_date, month)

        /** Set filter fields */
        homeStore.setFilterFields({
          cID: emit.companyId,
          start_date,
          end_date,
        })

        isCloseModal.value = true
        loaderStore.close()
      }
      if(emit.error)
        loaderStore.close()
    }

    /** Life Cycles */
    onMounted(handleInitFields)

    watch(() => { startDate.value, endDate.value }, () => handleDateWarning(), { deep: true })

    return {
      ptBr,
      startDate,
      endDate,
      isLoading,
      isActiveModal,
      isCloseModal,
      wrongField,
      dateWarning,
      handleResetFields,
      handleExecuteSearch,
    }
  },
})
